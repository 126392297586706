<template>
  <div class="resetPassword"></div>

</template>

<script>
export default {
  name: 'resetPassword'
}
</script>

<style lang="scss" scoped>
.resetPassword {
  height: 100%;
}
</style>